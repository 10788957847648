<style scoped>
.scroll-text{
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
<template>
  <v-footer
    inset
    app
    class="justify-center mx-0"
  >
    <v-tabs
      v-model="tab"
      app
      inset
      optional
      centered
      hide-slider
    >
      <v-tab
        v-for="item in links"
        :key="item.title.de"
      >
        {{ item.title[$i18n.locale] }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      app
    >
      <v-tab-item
        v-for="(item, id) in links"
        :key="id"
      >
        <v-card
          flat
          class="text-center"
        >
          <v-row>
            <v-col cols="2">
              <v-img
                v-if="id === 0"
                alt="Climatology Logo"
                class="shrink primary--text"
                contain
                src="@/assets/group_logo.jpg"
                transition="scale-transition"
                width="40"
              />
            </v-col>
            <v-col
              v-if="item.title.en=== 'Privacy Policy' || item.title.en=== 'Imprint'"

              cols="8"
              align="left"
              class="scroll-text"
            >
              <div v-html="item.text[$i18n.locale]" />
            </v-col>
            <v-col
              v-else
              cols="8"
            >
              {{ item.text[$i18n.locale] }}
            </v-col>
            <v-col cols="2">
              <v-icon
                right
                @click="tab = null"
              >
                {{ 'mdi-close' }}
              </v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-col
      class="primary py-1 text-center white--text"
      cols="12"
    >
      Version {{ version }} - {{ new Date().getFullYear() }} — {{ $t('footer.system') }}
    </v-col>
  </v-footer>
</template>

<script>
import Datenschutz from "raw-loader!@/assets/Datenschutz.html"
import Privacy from "raw-loader!@/assets/Privacy.html"
import Impressum from "raw-loader!@/assets/Impressum.html"
import Imprint from "raw-loader!@/assets/Imprint.html"
import DOMPurify from 'dompurify'
export default {
  name: 'Footer',
  data() {
    return {
      links: [
        {
          title: { de: 'Impressum', en: 'Imprint' },
          text: {de: DOMPurify.sanitize(Impressum), en: DOMPurify.sanitize(Imprint)},
        },
        {
          title: {de: 'Datenschutzerklärung', en: 'Privacy Policy'},
          text: {de: DOMPurify.sanitize(Datenschutz, {FORCE_BODY: true}), en: DOMPurify.sanitize(Privacy, {FORCE_BODY: true})},
        },
        {
          title: { de: 'Kontakt', en: 'contact' },
          text: {de: 'Dr. Marco Otto: webadmin@klima.tu-berlin.de', en: 'Dr. Marco Otto: webadmin@klima.tu-berlin.de'}
        },
      ],
      tab: null,
      version: process.env.VUE_APP_VERSION
    }
  },
}
</script>
