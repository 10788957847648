<template>
  <v-container>
    <ValidationObserver
      ref="obs"
      v-slot="{ invalid, validated, passes }"
    >
      <v-expansion-panels class="elevation-5">
        <v-expansion-panel
          color="primary"
          flat
        >
          <v-expansion-panel-header>{{ $t('account.change_password') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form>
              <ValidationProvider
                ref="password"
                v-slot="{ errors, valid }"
                name="password"
                rules="required|min:8"
              >
                <v-text-field
                  id="password"
                  v-model="password"
                  :error-messages="errors"
                  :success="valid"
                  :label="$t('account.password')"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                  required
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="password2"
                rules="required|confirmed:password"
                vid="pw2"
              >
                <v-text-field
                  id="password2"
                  v-model="password2"
                  :error-messages="errors"
                  :success="valid"
                  :label="$t('account.password2')"
                  name="password2"
                  prepend-icon="lock"
                  type="password"
                />
              </ValidationProvider>
            </v-form>
            <v-card-actions>
              <v-btn
                color="primary"
                :disabled="invalid || !validated"
                @click="passes(handleSubmit)"
                @keyup.enter="passes(handleSubmit)"
              >
                {{ $t('buttons.send') }}
              </v-btn>
            </v-card-actions>
            <v-overlay
              class="text-center"
              :absolute="absolute"
              :value="overlay"
              :opacity="1"
            >
              <Notification
                v-for="info in infos"
                :key="info.id"
              >
                <v-btn
                  :class="`${info.type} white--text v-btn v-size--large`"
                  @click="$router.go(); overlay = false"
                >
                  {{ $t('buttons.ok') }}
                </v-btn>
              </Notification>
            </v-overlay>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </ValidationObserver>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Notification from './Notification'

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

export default {
    name: 'ChangePassword',
    components: {
        ValidationObserver,
        ValidationProvider,
        Notification,
    },
    computed: {
      ...mapState({
        infos: state => state.alerts.infos,
        userId: state=> state.account.id
      })
    },
    methods: {
        ...mapActions({ patch: 'account/patch' }),
        handleSubmit() {
            this.submitted = true
            const password = this.password
            this.patch({ pk: this.userId, password })
            this.overlay = true
            // this.old_password = null
            this.password = null
            this.password2 = null
        },
    },
    data() {
        return {
            errors: [],
            // old_password: null,
            password: null,
            password2: null,
            submitted: false,
            // notification after submission
            absolute: true,
            overlay: false,
        }
    },
}
</script>
