<template>
  <v-row align="center">
    <div class="container">
      <form
        enctype="multipart/form-data"
        novalidate
      >
        <v-card
          class="dropbox primary accent--text text-center"
          outline
          block
          flat
          hover
          xs12
          md6
        >
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="uploading"
            fileCount="$event.target.files.length"
            accept="binary/*"
            class="input-file"
            @change="handleSubmit($event.target.files)"
          >
          <p v-if="!uploading">
            {{ $t('upload.droparea') }}
          </p>
          <p v-show="uploading">
            {{ $tc('upload.uploading1', fileCount, { count: fileCount }) }}
            {{ $tc('upload.uploading2', fileCount) }}
            {{ $t('upload.uploading3') }}
          </p>
          <v-text-field
            v-show="uploading"
            color="black"
            loading
            disabled
          />
        </v-card>
      </form>
    </div>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
    name: 'DropArea',
    data() {
        return {
            uploadError: null,
            uploadFieldName: 'netCDF',
            fileCount: null,
        }
    },
    computed: {
        ...mapGetters({
            allFiles: 'upload/allFiles',
            uploadedFiles: 'upload/uploadedFiles',
        }),
        ...mapState('upload', ['files', 'uploading']),
    },
    methods: {
        ...mapActions({
            uploadFiles: 'upload/uploadFiles',
        }),
        ...mapMutations({
            addFile: 'upload/addFile',
            removeFile: 'upload/removeFile',
            updateMessage: 'upload/updateMessage',
        }),
        handleSubmit(files) {
            if (!files.length) return

            for (let i = 0; i < files.length; i++) {
              let file = files.item(i)
                this.uploadFiles({
                    file,
                    file_type: 'UC2',
                    ignore_warnings: false,
                    ignore_errors: false,
                })
            }
        },
    },
}
</script>

<style lang="scss">
.dropbox {
    outline: 2px dashed black;
    outline-offset: -10px;
    color: 'warning';
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    z-index: 0;
    cursor: pointer;
    opacity: 50;
}

.input-file {
    opacity: 0;
    margin-left: -50%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: #f83e70;
    justify-self: center;
}
</style>
